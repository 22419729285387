//@import "bootstrap/dist/css/bootstrap.css";
@use "../../goldstar-share/src/app/style/style.variable.scss" as var;
@use "../../goldstar-share/src/app/style/style.material.scss";
@use "node_modules/bootstrap/dist/css/bootstrap.min.css";

@use "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

$primary: var.$primary-color;
$primary-light: var.$primary-color-light;
$secondary: var.$secondary-color;

@import "bootstrap";
// node_modules\golden-layout\dist\css\goldenlayout-base.css

/* You can add global styles to this file, and also import other style files */

html,
body {
	height: calc(100vh - 74px) !important;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.star-watermark {
	opacity: 0.05;
	z-index: -1;
	width: calc((60vw + 60vh) / 2);
	height: calc((60vw + 60vh) / 2);
	margin-top: calc((15vw + 20vh) / 2);
	margin-left: calc((14vw + 12vh) / 2);
}

.star-watermark-container {
	z-index: -1;
	overflow: hidden;
	width: calc((60vw + 60vh) / 2);
	height: calc((60vw + 60vh) / 2);
	position: fixed;
	bottom: 5px;
	right: 5px;
}

.mat-mdc-paginator-container {
	display: flex;
	align-items: center;
	justify-content: flex-start !important;
	padding: 0px !important;
}

.invalid-build-popup {
	z-index: 999999 !important;
}

.invalid-build-popup > .dx-overlay-content > .dx-popup-title {
	background-color: #024b81 !important;
	color: #fff !important;
}

.invalid-build-popup > .dx-overlay-content {
	background-image: url("../src/assets/images/TRB Star.png");
	background-size: 110px;
	background-repeat: no-repeat;
	background-position: right bottom;
}

app-file-acknowledge {
	height: 100%;
	width: 100%;
}

.mat-mdc-menu-panel {
	//  width: 700px !important;
	min-width: none !important;
	max-width: none !important;
	background-color: var.$primary-color;
}

app-custom-client-dialog {
	height: 100% !important;
}

/* You can add global styles to this file, and also import other style files */
// GOLDSTAR INTERNAL APP STYLE SHARE
.flex-container {
	display: flex;
}

.right-align {
	margin-left: auto;
}

app-custom-client-dialog {
	height: 100% !important;
}

/* Material style overrides */
.mat-mdc-raised-button.mat-primary {
	background-color: var.$primary-color !important;
	color: white !important;
}

.mat-mdc-raised-button.mat-accent {
	background-color: #8b7054 !important;
	color: white !important;
}

.mat-mdc-raised-button .mdc-button__label {
	text-decoration: none !important;
}

// * {
//   padding: 0;
//   margin: 0;
// }

body {
	a,
	.dropdown-item {
		text-decoration: none;
		color: white;

		&:hover {
			background-color: transparent;
			color: var.$secondary-color;
		}

		&:active {
			background-color: transparent;
			color: var.$secondary-color;
		}

		&:focus {
			background-color: transparent;
			color: var.$secondary-color;
		}
	}

	.nav-link {
		color: white !important;
	}

	ul {
		list-style-type: none;
	}

	// small{
	//   font-size: 14px;
	// }
	.navbar .megamenu {
		padding: 1rem;
	}

	// #scrollbar {
	//   overflow-y: scroll;
	//   overflow-x: hidden;
	//   height: 475px;
	//   &::-webkit-scrollbar {
	//     width: 7px;
	//   }
	//   &::-webkit-scrollbar-thumb {
	//     border-radius: 8px;
	//     background: #c2c9d2;
	//   }
	// }
	#hide {
		cursor: pointer;

		&:hover {
			color: var.$secondary-color;
		}
	}
}

.desk-navbar {
	background-color: var.$primary-color;
	border: none;

	.navbar-nav {
		.nav-item {
			.dropdown-menu {
				background-color: var.$primary-color;
				color: white;
				// border: 1px solid $secondary-color;
				height: 525px;

				.col-megamenu {
					.list-unstyled {
						span {
							font-size: 13px;
						}
					}

					.card {
						height: 8rem;
						background-color: var.$primary-color;
						border-radius: none;
						border: 0.5px solid var.$secondary-color;
						border-top: 4px solid var.$secondary-color;

						hr {
							margin: 0;
						}

						.card-title {
							font-size: 14px;
						}

						.card-text {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}

.customicon {
	font-size: 15px;
	height: 15px;
	width: 15px;
}

// @media (max-width: 991px) {
//   /* Rules for tablets and smaller viewports */
//   .desk-navbar{
//     background-color: black;
//     #third-end{
//       .col{
//         width: 100%;
//       }
//       .card{
//         width: 100%;
//       }
//     }
//   }
// }

/* ============ desktop view ============ */

@media (min-width: 992px) {
	.desk-navbar .has-megamenu {
		position: static !important;
	}

	.desk-navbar .megamenu {
		left: 40px;
		right: 0;
		top: auto;
		width: 95%;
		// margin-top: 0;
	}

	.mob-navbar {
		display: none;
	}
}

/* ============ desktop view .end// ============ */

.mob-navbar {
	background-color: var.$primary-color;

	.dropdown-menu {
		background-color: var.$primary-color;
		color: white;
		border: none;

		.dropdown-item {
			font-size: 15px;
		}
	}
}

/* ============ mobile view ============ */

@media (max-width: 991px) {
	.desk-navbar {
		display: none;
	}

	.mob-navbar {
		position: static !important;
	}

	.mob-navbar {
		width: 100%;
	}
}

/* ============ mobile view .end// ============ */

.heading-content {
	border-bottom: 2px solid rgb(0 0 0 / 42%);
	padding: 10px 0;
	margin: 0 0 16px 0;

	/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
	mat-slide-toggle {
		color: #333333;
	}

	h1 {
		margin: 0;
	}
}

.main-header-space {
	padding: 15px 17px;

	@media screen and (max-width: 411px) {
		padding: 15px 5px;
		text-align: center;
	}
}

.header-bg {
	background-color: var.$primary-color;
	color: white;
}

.btn-color {
	background-color: var.$primary-color !important;
	color: white !important;
}

.btn-height {
	height: 40px;

	@media screen and (max-width: 767px) {
		margin-top: 8px !important;
		margin-bottom: 8px !important;
	}
}

.overflow-hidden {
	overflow: hidden;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
	background-color: #005288;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
	background-color: #ffffff;
}

mat-form-field {
	padding-bottom: 8px;
	position: relative;

	mat-label {
		padding-left: 8px;
	}

	input {
		padding-left: 8px !important;
	}

	mat-error {
		padding-left: 8px;
	}

	.down-icon {
		position: absolute;
		right: 8px;
		top: 5px;
	}
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-mdc-form-field .mat-mdc-select.mat-select-invalid .mat-select-arrow {
	color: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value {
	padding-left: 0.25em !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
mat-progress-bar {
	height: 10px !important;
	border-radius: 10px;
}

.content-stepper {
	// min-height: calc(100vh - 110px);
	//max-height: calc(100vh - 120px);
	width: 100%;
	background: #f1f1f1;
}

/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-fill::after {
	background-color: #327ebc;
}

/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.mat-progress-bar-buffer {
	background-color: #d2d2d2;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
	width: 24px !important;
	height: 24px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-frame {
	border-color: rgba(0, 0, 0, 0.3) !important;
	border-width: 1px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: #8b7054;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #8b7054;
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #8b7054;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label-content {
	white-space: normal;
}

.overflow-content {
	max-height: calc(100vh - 40vh);
	overflow: auto;

	@media screen and (min-height: 568px) {
		max-height: calc(100vh - 56vh);
	}

	@media screen and (min-height: 640px) {
		max-height: calc(100vh - 47vh);
	}

	@media screen and (min-height: 731px) {
		max-height: calc(100vh - 42vh);
	}

	@media screen and (min-height: 823px) {
		max-height: calc(100vh - 38vh);
	}
}

h1 {
	@media screen and (max-width: 320px) {
		font-size: 20px;
	}
}

.res-row {
	@media screen and (max-width: 425px) {
		align-items: flex-start !important;
	}

	.overflow-hidden {
		@media screen and (max-width: 425px) {
			overflow: visible !important;
			border-radius: 0 !important;
			box-shadow: none !important;
		}
	}
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
mat-checkbox {
	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	.mat-checkbox-layout {
		white-space: normal;
	}

	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
	.mat-checkbox-inner-container {
		margin-top: 0;
	}
}

// .progressbar {
//     width: 80%;
//     margin: 0 auto;
// }

app-client-header {
	/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
	/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
	.mat-button-wrapper {
		color: white;
	}
}

.row {
	margin: 0;
}

.cursor-pointer {
	cursor: pointer;
}

.app-main-container {
	margin-top: 60px;
}

.backdropBackground {
	/* filter: blur(20px); */
	background-color: #00000080;
	opacity: 0.9 !important;
}

/* schedule appointment style  start */

:root {
	--custom-title: #393939;
	--custom-white: #fff;
}

@font-face {
	font-family: Lato-Bold;
	src: url(../src/assets/fonts/Lato-Bold.ttf);
}

@font-face {
	font-family: Lato-Medium;
	src: url(../src/assets/fonts/Lato-Medium.ttf);
}

@font-face {
	font-family: Lato-Regular;
	src: url(../src/assets/fonts/Lato-Regular.ttf);
}

p {
	font-family: Lato-Regular;
}

a {
	font-family: Lato-Regular;
	cursor: pointer;
}

body {
	background-color: #f5f5f5;
}

//loader
.appLoader .ngx-spinner-overlay {
	top: 0% !important;
	left: 0% !important;
}

/* Header */

.scheduling-appointment-header {
	padding: 15px;
	background-color: var(--custom-white);
	box-shadow: 0 3px 6px #00000029;
	text-align: center;
	margin-top: 8px;

	@media screen and (max-width: 767px) {
		margin-top: 24px !important;
	}
}

.scheduling-appointment-header .header-text {
	margin-bottom: 0 !important;
	font-family: Lato-Bold;
	font-size: 20px;
}

/* Header End */

/* Sidebar */

.scheduling-appointment-sidebar {
	padding: 18px 0 0 16px;
}

.scheduling-appointment-sidebar .book-appointment-progress-sec-header p {
	font-family: Lato-Regular;
	font-size: 13px;
	margin-bottom: 1rem;
	color: #616161;
}

.sidebar-progress-sec .sidebar-step-fbox {
	display: flex;
	align-items: center;
}

.step-indication {
	background-color: #757575;
	color: var(--custom-white);
	// border-radius: 50%;
	min-height: 25px;
	min-width: 25px;
	margin-right: 10px;
	text-align: center;
	align-content: center;
	align-self: center;
}

.step-name {
	font-family: Lato-Medium;
	font-size: 16px;
	color: #616161;
}

.step-name.active {
	color: #212121;
}

.step-indication img {
	width: 15px;
	margin-bottom: 2px;
	margin-right: 1px;
}

.step-indication span {
	font-size: 13px;
	font-family: Lato-Regular;
}

.step-indication.active {
	background-color: var.$primary-color !important;
}

.step-indication.completed {
	background-color: var.$primary-color !important;
}

.content-selected-brief-sec {
	padding: 4px 11px;
}

.content-selected-brief-sec .content-selected-brief {
	border-left: 1px solid #e0e0e0;
	padding: 0 0 0 22px;
	min-height: 15px;
}

.content-selected-brief-main {
	padding-bottom: 12px;
}

.content-selected-brief .brief-header {
	font-family: Lato-Bold;
	font-size: 12px;
	color: #212121;
	margin-bottom: 0;
}

.content-selected-brief .brief-description {
	font-family: Lato-Regular;
	font-size: 12px;
	color: #212121;
	margin-bottom: 0;
	padding-left: 25px;
}

.open-sidebar {
	position: absolute;
	top: 42px;
	left: 12px;

	@media screen and (min-width: 760px) and (max-width: 1020px) {
		top: 64px;
	}
}

.sidenav.full-width {
	width: 250px !important;
}

@media screen and (max-width: 767px) {
	.scheduling-appointment-sidebar {
		padding: 40px 0 0 16px !important;
	}

	.sidenav .closebtn {
		top: 70px !important;
	}
}

@media screen and (max-width: 991px) {
	.scheduling-appointment-sidebar-display {
		display: none;
	}

	.sidenav {
		height: 100%;
		width: 0;
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		background-color: #f0f0f0;
		overflow-x: hidden;
		transition: 0.5s;
		padding-top: 60px;
	}

	.sidenav .closebtn {
		position: absolute;
		top: 50px;
		right: 6px;
		margin-left: 0px;
		font-size: 36px;
		color: #818181;
		text-decoration: none;
	}
}

@media screen and (min-width: 992px) {
	.sidenav .closebtn {
		display: none;
	}

	.open-sidebar {
		display: none;
	}
}

/* Sidebar End */

.main-content-sec {
	margin-top: 18px;
	box-shadow: 0px 3px 6px #00000029;
}

.service-sidebar {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: #fcfcfc;
	/* box-shadow: 0px 3px 6px #00000029; */
	height: fit-content;
	padding-top: 16px;
	/* min-height: 358px; */
	min-height: 100%;

	@media screen and (max-width: 767px) {
		border-right: 2px solid #e0e0e0;
		border-radius: 4px;
	}
}

.service-sidebar .service-sidebar-header {
	padding-inline: 15px;
}

.service-sidebar .service-sidebar-header p {
	font-family: Lato-Regular;
	font-size: 13px;
	margin-bottom: 1rem;
}

.tab-right {
	@media screen and (min-width: 768px) {
		padding-right: 0px !important;
	}
}

.activity-link-tab {
	padding-top: 0px !important;
	padding-bottom: 15px;
}

.service-sidebar .tablinks span {
	margin: auto;
	margin-left: 3%;
	vertical-align: text-top;
	font-family: Lato-Bold;
}

/* .service-sidebar .tab button:hover {
    background-color: #E8E5FB;
  } */

.service-sidebar .tab button.active {
	background-color: rgba(148, 120, 91, 0.08);
	border-right: none;
	border-left: 2px solid var.$primary-color;
	border-top: none;
	border-bottom: none;
	width: 100%;
	z-index: 9;

	img {
		visibility: visible;
	}
}

.service-sidebar .tab button:hover {
	img {
		visibility: visible;
	}
}

.service-sidebar .tab button {
	display: block;
	background-color: inherit;
	color: #212121;
	padding: 7px 6px;
	width: 100%;
	border: none;
	outline: none;
	text-align: left;
	cursor: pointer;
	transition: 0.3s;
	font-size: 16px;

	/* font-family: Montserrat-regular; */
	img {
		visibility: hidden;
		height: 20px;
	}
}

.col-pad-left {
	padding-left: 0;

	@media screen and (max-width: 767px) {
		margin-top: 16px !important;
	}
}

.tabcontent {
	min-height: 430px;
	padding: 17px 24px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	/* box-shadow: 0px 3px 6px #00000029; */
	position: relative;
	/* max-width: 830px; */
	background-color: white;
}

.content-sec-header {
	font-family: Lato-Bold;
	color: #212121;
	display: flex;
	justify-content: space-between;
}

.content-sec-header h4 {
	font-size: calc(1.275rem + 0.3vw) !important;
	font-weight: 500;
}

.scheduler-box-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
	flex-wrap: wrap;
}

.service-selection-sec {
	border: 1px solid #bcbcbc;
	border-radius: 6px;
	text-decoration: none;
	color: #212121;
	height: 150px;
	width: 200px;
	align-content: center;
	text-align: center;
	background-color: white;

	mat-icon {
		color: black;
	}
}

.service-selection-sec:hover {
	border: 2px solid var.$trb-gold;
	border-radius: 6px;
	background-color: var.$trb-blue;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	color: white !important;

	mat-icon {
		color: white !important;
	}
}

.tabcontent .service-duration-fbox {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 6px 12px;
	border-bottom: 1px solid #bcbcbc;
}

.duration-text p {
	margin-bottom: 0 !important;
}

.tabcontent .type-selection {
	// padding: 6px 45px 6px 12px;
	// position: relative;
	padding: 10px;
}

.tabcontent .type-selection .type-header p {
	margin-bottom: 3px !important;
	font-family: Lato-Bold;
	font-size: 15px;
	pointer-events: none;
}

.tabcontent .type-selection .type-description p,
.tabcontent .type-selection .type-description li {
	margin-bottom: 0 !important;
	font-family: Lato-Regular;
	font-size: 15px;
	color: #616161;
}

.tabcontent .type-selection .type-description ul {
	margin-bottom: 0.5rem !important;
	list-style: disc;
}

.service-selection-sec .right-fa-icon {
	position: absolute;
	right: 15px;
	// top: 33%;
	color: #212121;
}

#more {
	display: none;
}

.read-more-btn {
	width: 100%;
	background-color: transparent;
	border: none;
	text-align: left;
	color: var.$primary-color;
}

.schedule-service-main .main-content-sec {
	margin-top: 18px;
	box-shadow: none !important;

	@media screen and (min-width: 768px) {
		box-shadow: 0px 3px 6px #00000029;
	}
}

.box-shadow-custom {
	@media screen and (max-width: 767px) {
		box-shadow: 0px 3px 6px #00000029;
	}
}

.back-btn-sec {
	background-color: #fff;
	padding-inline: 15px;
	padding-block: 15px;
	border-bottom: 1px solid #e0e0e0;
}

.back-btn {
	background-color: transparent;
	border: none;
	color: #616161;
	font-size: 0.875rem;
}

.main-content-sec .type-selection-fbox {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 10px;
}

.main-content-sec .icon-sec {
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	background: #f5f5f5;
	align-items: center;
	border-radius: 50%;
	justify-content: center;
}

.main-content-sec .tabcontent {
	min-height: 430px;
	padding: 17px 24px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: white;
	/* box-shadow: 0px 3px 6px #00000029; */
	position: relative;

	/* max-width: 830px; */
	@media screen and (max-width: 767px) {
		border-radius: 4px;
		padding: 5px 0px !important;
	}
}

.main-content-sec .appointment-selection-sec {
	border: 1px solid #bcbcbc;
	display: flex;
	border-radius: 6px;
	text-decoration: none;
	// flex-direction: column;
	color: #212121;
	height: 150px;
	width: 200px;
	align-content: center;
	text-align: center;
	justify-content: center;
}

@media screen and (min-width: 1024px) and (max-width: 1150px) {
	.responsive-custom-width {
		width: 30% !important;
	}
}

.main-content-sec .appointment-selection-sec:hover {
	border: 2px solid var.$trb-gold;
	border-radius: 6px;
	background-color: var.$trb-blue;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	color: white !important;
}

.main-content-sec .tabcontent .service-duration-fbox {
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 6px 12px;
	border-bottom: 1px solid #bcbcbc;
}

.main-content-sec .tabcontent .type-selection {
	// padding: 20px 45px 20px 12px;
	// position: relative;
	padding: 10px;
	align-content: center;
}

.main-content-sec .tabcontent .type-selection .type-header p {
	margin-bottom: 3px !important;
	font-family: Lato-Bold;
	font-size: 15px;
}

.main-content-sec .tabcontent .type-selection .type-description p {
	margin-bottom: 0 !important;
	font-family: Lato-Regular;
	font-size: 15px;
	color: inherit;
}

.show-more {
	max-height: none !important;
}

.main-content-sec .tabcontent .type-selection .type-description {
	max-height: 80px;
	overflow: hidden;
}

.toggle-content {
	color: var.$primary-color;
	cursor: pointer;
	display: block;
	font-size: 0.875rem;
	line-height: 1;
	padding-top: 1rem;
	padding-right: 1rem;
}

.main-content-sec .appointment-selection-sec .right-fa-icon {
	position: absolute;
	right: 20px;
	top: 33%;
	color: #212121;
}

.language-preference {
	.right-fa-icon {
		top: 24% !important;
	}

	.tabcontent .type-selection {
		padding: 10px 45px 10px 12px !important;
	}
}

.schedule-content-parent-class {
	display: contents !important;
}

.schedule-content-parent-class .dx-checkbox-container {
	align-items: baseline !important;
	padding-top: 2px;
}

.schedule-content-header {
	@media screen and (max-width: 767px) {
		position: sticky;
		top: 40px;
		z-index: 99;

		& .postion-rel {
			position: relative;
		}
	}
}

.main-content-sec .location-selection-main {
	margin-block: 0px;
	box-shadow: 0px 3px 6px #00000029;
	background-color: #fff;
	border-radius: 4px;
}

.location-selection-sec {
	// position: absolute;
	// top: 6.5rem;
	// width: 30%;

	@media screen and (max-width: 767px) {
		position: unset;
		width: 100% !important;
	}
}

.main-content-sec .back-btn mat-icon {
	margin-right: 10px;
}

.main-content-sec .content-sec {
	padding: 17px 24px;
	background-color: white;

	@media screen and (max-width: 767px) {
		padding: 17px 15px !important;
	}
}

.main-content-sec .tabcontent {
	min-height: 430px;
	max-height: 100%;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	position: relative;
	overflow-y: auto;
}

.main-content-sec .tab-top-sec {
	border-bottom: 1px solid #e0e0e0;
}

.main-content-sec .location-near-me-btn {
	border: 1px solid var.$primary-color;
	padding: 0.5rem 0.75rem;
	background: transparent;
	border-radius: 0.25rem;
	color: var.$primary-color;
	width: 100%;
	font-family: Lato-Medium;
}

.main-content-sec .location-near-me-btn i {
	font-size: 22px;
	margin-right: 10px;
}

.main-content-sec .location-near-me-btn:hover {
	border: 1px solid var.$primary-color;
	background-color: var.$primary-color;
	color: #fff;
}

.searchBar {
	margin-top: 1rem;
	width: 100%;
	background-color: #f5f5f5;
	position: relative;
}

.searchQueryInput {
	color: #212121;
	width: 100%;
	display: block;
	padding: 1rem 2.5rem 1rem 0.75rem;
	font-size: 1rem;
	transition: box-shadow 0.1s;
	border-radius: 0.25rem;
	background: #f5f5f5;
	border: none;
}

.searchQuerySubmit {
	top: 50%;
	background-color: white;
	color: #616161;
	right: 0;
	position: absolute;
	transform: translate(-50%, -45%);
}

.searchQuerySubmit:hover {
	cursor: pointer;
}

.searchQueryInput:focus-visible {
	outline: 2px solid var.$primary-color;
	box-shadow:
		0 4px 6px -1px rgba(0, 0, 0, 0.3),
		0 2px 4px -1px rgba(0, 0, 0, 0.12);
	outline-offset: -2px;
}

.search-map-fbox {
	display: flex;
	align-items: center;
}

.searchbar-sec {
	flex: 1;
}

.map-btn {
	margin-top: 1rem;
	width: 32px;
	height: 32px;
	margin-left: 12px;
	display: flex;
	background: #ffffff;
	align-items: center;
	justify-content: center;
	border: 1px solid #e0e0e0;
	border-radius: 0.25rem;
}

.schedule-location-outermap {
	display: none !important;
}

.schedule-location-innermap {
	display: none !important;
}

.schedule-location-innermap.show-map {
	display: block;
}

.outer-map-content-hide {
	display: none;
}

.outer-map-content-show {
	display: block;
	// max-height: 40vh !important;
}

.tabcontent .dx-accordion-wrapper .dx-empty-message {
	text-align: center !important;
}

.main-content-sec .tabcontent.custom-height {
	height: calc(82vh - 265px) !important;
	min-height: calc(82vh - 265px) !important;
	max-height: calc(82vh - 265px) !important;
}

@media screen and (max-height: 1020px) {
	.main-content-sec .tabcontent.custom-height {
		height: calc(80vh - 245px) !important;
		min-height: calc(80vh - 245px) !important;
		max-height: calc(80vh - 245px) !important;
	}
}

@media screen and (min-width: 768px) {
	.map-btn {
		display: none;
	}

	.schedule-location-outermap {
		display: block !important;
	}
}

.main-content-sec .tabcontent-selection {
	padding: 1.25rem 24px;
	flex-grow: 1;
	border-bottom: 1px solid #e0e0e0;
}

.main-content-sec .info-content-select-fbox {
	display: flex;
	justify-content: space-between;
}

.main-content-sec .drop-sec {
	width: 70%;
}

.main-content-sec .drop-btn {
	width: 85%;
	border: 0 none;
	cursor: pointer;
	margin: 0;
	display: block;
	padding: 0;
	flex-grow: 1;
	min-width: 0;
	background: transparent;
	box-sizing: border-box;
	text-align: left;
	-webkit-appearance: none;
}

.main-content-sec .drop-btn .drop-btn-header {
	color: #212121;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1rem;
	margin-bottom: 5px;
}

.main-content-sec .content-subtitle {
	color: #212121;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-family: Lato-Regular;
	margin-bottom: 0 !important;
}

.main-content-sec .content-subtitle.content-subtitle-sliced {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.main-content-sec .drop-icon {
	fill: #757575;
	float: right;
	transform: translateY(-3px);
}

.main-content-sec .select-location-btn {
	color: #ffffff;
	border: none;
	background: var.$primary-color;
	border-radius: 0.25rem;

	cursor: pointer;
	margin: 0;
	display: block;
	padding: 0.75rem;
	min-width: 4rem;
	box-sizing: border-box;
	transition: border 0.1s;
	/* border-radius: 0.125rem; */
	-webkit-appearance: none;
	font-family: Lato-Medium;
	font-size: 15px;
}

.main-content-sec .select-location-btn:hover {
	background: $primary-light;
}

.main-content-sec .tab-section {
	margin-top: 1.5rem;
}

.main-content-sec .tab-section.tab-section-hidden {
	display: none;
}

.main-content-sec .tab-section-header h2 {
	color: #212121;
	font-size: 15px;
	font-weight: 500;
	line-height: 1rem;
	margin-bottom: 5px;
}

.main-content-sec .tab-section-content-fbox {
	display: flex;
}

.main-content-sec .tab-section-content-fbox .week-day {
	width: 45%;
}

.main-content-sec .tab-section-content-fbox .time-slot {
	width: 55%;
}

.main-content-sec .tab-section-content-fbox span {
	color: #616161;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-weight: 400;
	font-family: Lato-Regular;
}

// form styles
.mandatoryField {
	color: red;
}

.position-rel {
	position: relative;
}

.error-text {
	display: none;
}

.validation-error .error-text {
	color: #dd2b27 !important;
	font-size: 12px;
	display: block !important;
}

.validation-error .error-symbol {
	top: 10px;
	right: 12px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.validation-error .dx-error-symbol {
	top: 12px;
	right: 12px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.validation-error .select-error-symbol {
	top: 8px;
	right: 38px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.validation-error .dx-select-error-symbol {
	top: 12px;
	right: 4px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container::after {
	display: none !important;
}

app-customer-details {
	.validation-error .error-symbol {
		top: 16px;
		right: 12px;
	}

	.validation-error .dx-error-symbol {
		top: 16px;
		right: 12px;
	}

	.validation-error .select-error-symbol {
		top: 8px;
		right: 38px;
	}

	.validation-error .dx-select-error-symbol {
		top: 16px;
		right: 5px;
	}
}

.dialog-container {
	.validation-error .error-symbol {
		top: 10px;
		right: 12px;
	}

	.validation-error .dx-error-symbol {
		top: 10px;
		right: 12px;
	}

	.validation-error .select-error-symbol {
		top: 8px;
		right: 38px;
	}

	.validation-error .dx-select-error-symbol {
		top: 9px;
		right: 24px;
	}
}

.error-icon-placement-style {
	top: 14px !important;
	right: 44px !important;
}

.error-symbol,
.select-error-symbol,
.dx-error-symbol,
.dx-select-error-symbol {
	display: none;
}

.position-rel .error-text {
	display: none;
}

.position-rel .error-icon-placement {
	top: 10px;
	right: 36px;
}

.position-rel .error-icon-placement-extended {
	top: 10px;
	right: 0px;
}

.button-icons {
	border: none;
	background-color: unset;
	padding: unset;
}

//meeting detail styles

.meeting-header {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.meeting-header button {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	color: #616161;
	cursor: pointer;
	font-size: 15px;
}

.meeting-header button .mat-icon {
	margin-right: 10px;
}

app-staff-schedule-details {
	#calendar {
		display: flex;
	}

	.calendar-container {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
	}

	span[class*="weekend dx-template-wrapper"],
	span[class*=" dx-template-wrapper"],
	span[class*="dx-template-wrapper "] .holiday {
		text-shadow: none;
		font-weight: bold;
	}

	span[class*="weekend dx-template-wrapper"] {
		color: #757575;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAm0lEQVRIx7XVwQnAIAwF0ExSdBF1a6Er9dIRqsVAazWJmh4+iuBT4YMQ4w4pWxk1clt5YlOOFKeAumJZXAgKOKIBb6yBv9AansU/aAsexZtoD5biXZSCOZxEObiHs6gErnERKoURP0uCZM9IpRB2WvDz+eIqzvRUhMNkT1mcQz1xsKfwWZTFV1ASX0W7uAbaxPOCUUBr3MBfn+kF3CNLT2/yky4AAAAASUVORK5CYII=)
			center center no-repeat;
		cursor: default;
		font-weight: 400;
	}

	span[class*="weekend dx-template-wrapper"] {
		color: #8080ff;
		cursor: default;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAm0lEQVRIx7XVwQnAIAwF0ExSdBF1a6Er9dIRqsVAazWJmh4+iuBT4YMQ4w4pWxk1clt5YlOOFKeAumJZXAgKOKIBb6yBv9AansU/aAsexZtoD5biXZSCOZxEObiHs6gErnERKoURP0uCZM9IpRB2WvDz+eIqzvRUhMNkT1mcQz1xsKfwWZTFV1ASX0W7uAbaxPOCUUBr3MBfn+kF3CNLT2/yky4AAAAASUVORK5CYII=)
			center center no-repeat;
	}

	span[class*="holiday dx-template-wrapper"] {
		color: #ff3030 !important;
		cursor: default;
	}

	.holiday:hover {
		color: #ff3030 !important;
		cursor: default;
	}

	.dx-calendar {
		width: 100% !important;
		min-width: auto !important;
	}

	.dx-calendar-cell .dayBooked {
		color: #757575 !important;
		cursor: default;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAm0lEQVRIx7XVwQnAIAwF0ExSdBF1a6Er9dIRqsVAazWJmh4+iuBT4YMQ4w4pWxk1clt5YlOOFKeAumJZXAgKOKIBb6yBv9AansU/aAsexZtoD5biXZSCOZxEObiHs6gErnERKoURP0uCZM9IpRB2WvDz+eIqzvRUhMNkT1mcQz1xsKfwWZTFV1ASX0W7uAbaxPOCUUBr3MBfn+kF3CNLT2/yky4AAAAASUVORK5CYII=)
			center center no-repeat;
	}

	.dx-calendar-body thead th {
		text-transform: capitalize;
	}
}

.dialog-container {
	display: flex;
	flex-direction: column;

	& .header-container {
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		padding: 20px 0px;

		& span {
			color: #212121;
			font-size: 18px;
			font-family: "Lato", sans-serif;
			font-weight: 600;
		}
	}

	& .body-container {
		margin: 0.5rem;
		border: none;
		padding: 0px;
	}

	& .footer-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-top: 1px solid #ccc;
		padding: 20px;

		& a {
			display: flex;
			width: 100px;
			height: 30px;
			border: none;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 5px;
			color: white;
			align-self: center;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			font-family: "Lato", sans-serif;
			cursor: pointer;
			padding: 20px;
			font-weight: 600;
		}

		& .cancel-btn {
			background-color: transparent;
			margin-right: 10px;
			color: var.$primary-color;
			border: 1px solid var.$primary-color;
		}

		& .submit-btn {
			color: #ffffff;
			border: none;
			background: var.$primary-color;
		}
	}
}

//staff details style

.main-content-sec .content-sec-header {
	font-family: Lato-Bold;
	color: #212121;
	margin-bottom: 1.5rem;
}

.timezone-fbox {
	display: flex;
	align-items: center;
	margin-top: 1rem;
	gap: 8px;
}

.globe-icon-sec {
	display: flex;
	align-items: center;
	margin-right: 8px;
}

.showing-time-txt {
	color: #616161;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-family: Lato-Regular;
	// margin-right: 5px;
	width: 100%;
	text-align: center;
}

.timezone {
	color: var.$primary-color;
	font-family: Lato-Bold;
	text-decoration: none;
}

.session-header h6 {
	color: #212121;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 0.75rem;
	line-height: 1.3125rem;
	font-family: Lato-Bold;
}

.main-content-sec .timeslot-list {
	margin-bottom: 2.5rem;
	list-style: none;
	padding-left: 0;
}

.main-content-sec .timeslot-list-item {
	display: inline-block;
	min-width: 5.5rem;
	margin-right: 0.625rem;
	margin-bottom: 0.625rem;
}

.selected-time-slot {
	background-color: var.$primary-color !important;
	color: white !important;
}

.main-content-sec .timeslot-btn {
	border: 1px solid #e0e0e0;
	padding: 0.5rem 0.75rem;
	background: transparent;
	border-radius: 0.8rem;
	width: 110px;
	cursor: pointer;
	margin: 0;
	display: block;
	/* padding: 0.75rem; */
	min-width: 4rem;
	box-sizing: border-box;
	transition: border 0.1s;
}

.main-content-sec .timeslot-btn:hover {
	color: #ffffff;
	background: var.$primary-color;
	border-color: var.$primary-color;
}

.main-content-sec .timeslot-btn-text {
	color: var.$primary-color;
	font-weight: 400;
	text-transform: none;
	font-family: Lato-Medium;
	font-size: 0.875rem;
	line-height: 1.1875rem;
}

.main-content-sec .timeslot-btn:hover .timeslot-btn-text {
	color: #ffffff;
}

@media screen and (min-width: 768px) {
	.main-content-sec .sec-divider-line {
		border-left: 1px solid #e0e0e0;
	}
}

.main-content-sec .time-slot-selection-sec {
	padding: 16px 12px;
}

.main-content-sec .calender-sec {
	// padding: 16px 12px;
	flex-direction: column;
	background-color: transparent;
	margin: 18px 0.5rem;

	.date-label {
		text-align: center;
		font-size: calc(1.275rem + 0.3vw) !important;
		font-weight: 500;
	}
}

.main-content-sec .other-location-section-header h5 {
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.625rem;
	color: #212121;
	font-family: Lato-Bold;
	margin-bottom: 1.5rem;
}

.main-content-sec .session-header p {
	color: #616161;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-weight: 400;
	font-family: Lato-Medium;
}

.main-content-sec .view-more-timeslots-btn {
	color: var.$primary-color;
	border: none;
	display: inline-block;
	padding: 0;
	min-width: 0;
	background: none;
	white-space: nowrap;
	border-bottom: 1px solid var.$primary-color;
	text-decoration: none;
	cursor: pointer;
	transition: border 0.1s;
}

.main-content-sec .view-more-timeslots-btn:hover {
	color: $primary-light;
	border-bottom-color: $primary-light;
}

.main-content-sec .view-more-timeslots-btn-text {
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-family: Lato-Bold;
}

.main-content-sec .staff-selection-main {
	margin-block: 18px;
	box-shadow: 0px 3px 6px #00000029;
	background-color: #fff;
	border-radius: 4px;
}

.main-content-sec .content-sec {
	padding: 17px 24px;
}

.main-content-sec .tabcontent {
	min-height: 430px;
	max-height: 100%;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	position: relative;
	overflow-y: auto;
}

.main-content-sec .tab-top-sec {
	border-bottom: 1px solid #e0e0e0;
}

.main-content-sec .content-sec-header {
	font-family: Lato-Bold;
	color: #212121;
}

.searchBar {
	margin-top: 1rem;
	width: 100%;
	/* display: flex; */
	/* flex-direction: row; */
	/* align-items: center; */
	background-color: #f5f5f5;
	position: relative;
}

.searchQueryInput {
	color: #212121;
	width: 100%;
	display: block;
	padding: 1rem 2.5rem 1rem 0.75rem;
	font-size: 1rem;
	transition: box-shadow 0.1s;
	border-radius: 0.25rem;
	background: #f5f5f5;
	border: none;
}

.searchQuerySubmit {
	top: 50%;
	color: #616161;
	right: 0;
	position: absolute;
	transform: translate(-50%, -45%);
}

.searchQueryInput:focus-visible {
	outline: 2px solid var.$primary-color;
	box-shadow:
		0 4px 6px -1px rgba(0, 0, 0, 0.3),
		0 2px 4px -1px rgba(0, 0, 0, 0.12);
	outline-offset: -2px;
}

.main-content-sec .staff-selection-list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 24px;
	border-bottom: 1px solid #e0e0e0;
	cursor: pointer;
}

.main-content-sec .staff-selection-list-item .item-header {
	max-width: 65%;
}

.main-content-sec .staff-selection-list-item .item-header h2 {
	color: #212121;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.3125rem;
	font-family: Lato-Bold;
	margin-bottom: 0;
}

.main-content-sec .staff-selection-list-item .item-header p {
	color: #616161;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-family: Lato-Regular;
	margin-bottom: 0;
}

.main-content-sec .staff-selection-list-item .language-short-fbox {
	display: flex;
	flex-wrap: wrap;
	padding-block: 0.25rem;
}

.main-content-sec .staff-selection-list-item .language-short {
	padding: 0.25rem;
	margin-bottom: 0.125rem;
	margin-right: 0.25rem;
	font-size: 0.75rem;
	border-radius: 0.25rem;
	background-color: rgba(0, 0, 0, 0.1);
	font-family: Lato-Regular;
}

.main-content-sec .staff-selection-list-item .item-selection {
	color: $primary-light;
	display: flex;
	padding: 0.5rem 0.75rem;
	min-height: 1.5rem;
	align-items: center;
	border-radius: 0.25rem;
	text-decoration: none;
}

.main-content-sec .staff-selection-list-item .item-selection.item-selected {
	color: #2e7d32 !important;
	display: flex;
	margin-left: 0.8125rem;
	font-family: Lato-Medium;
}

.main-content-sec .staff-selection-list-item .item-selection.item-selected svg {
	display: block !important;
	fill: #2e7d32;
	margin-right: 0.5rem;
}

.main-content-sec .staff-selection-list-item .item-selection svg {
	display: none;
}

.main-content-sec .time-slot-selection-sec {
	padding: 16px 0.5rem;
}

.main-content-sec .time-slot-selection-sec-header {
	margin-bottom: 1.875rem;
}

.main-content-sec .time-slot-selection-sec-header-text {
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.625rem;
	color: #212121;
	font-family: Lato-Bold;
}

.main-content-sec .time-slot-selection-text {
	margin-top: 0.5rem;
	color: #616161;
	font-size: 0.875rem;
	line-height: 1.1875rem;
	font-family: Lato-Regular;
	margin-bottom: 0;
}

.reschedule-sec .custom-height {
	max-height: 200px;
	overflow-y: auto;
}

app-customer-details {
	.dx-texteditor.dx-editor-outlined {
		border: 1px solid #949494;
		padding: 0.5rem;
	}

	.dx-texteditor-input-container::after {
		display: none !important;
	}
}

app-attendee-create-dialog {
	.dx-texteditor-input-container::after {
		display: none !important;
	}
}

@media screen and (max-width: 1500px) {
	.main-content-sec .staff-selection-list-item .item-header {
		max-width: 55%;
	}
}

@media screen and (min-width: 1100px) and (max-width: 1270px) {
	.main-content-sec .staff-selection-list-item .item-header {
		max-width: 45%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1099px) {
	.main-content-sec .staff-selection-list-item .item-header {
		max-width: 30%;
	}
}

/* Schedule appointment Success  */
.main-content-sec .success-msg-sec {
	text-align: center;
	margin-top: 18px;
}

.main-content-sec .appointment-details-sec {
	margin-block: 24px;
	box-shadow: 0px 3px 6px #00000029;
	background-color: #fff;
	border-radius: 4px;
	padding: 12px 18px;
}

.main-content-sec .success-img-sec svg {
	width: 50px;
	height: 50px;
}

.main-content-sec .success-msg-sec h2 {
	font-size: 26px;
	font-family: Lato-Bold;
	color: #303030;
	margin-bottom: 16px;
	margin-top: 8px;
}

.main-content-sec .success-msg-sec p {
	font-size: 17px;
	font-family: Lato-Medium;
	color: #303030;
}

.main-content-sec .success-msg-sec h6 {
	font-size: 17px;
	font-family: Lato-Bold;
	color: #303030;
	margin-bottom: 0;
}

.main-content-sec .appointment-details-header-sec {
	display: flex;
	align-items: center;
	gap: 12px;
}

.main-content-sec .appointment-details-header-sec mat-icon {
	width: 25px;
	margin-top: 5px;
	color: #003c69;
}

.main-content-sec .appointment-details-header-sec h3 {
	color: #303030;
	font-size: 20px;
	font-family: Lato-Bold;
	margin-bottom: 0;
}

.main-content-sec .details-main-sec {
	padding: 12px 26px;
}

.main-content-sec .meeting-details-section {
	padding: 12px 0px 12px 26px;
}

.meeting-details-section .detail-value {
	border-radius: 3px;
	padding: 10px;
	border-left: 4px solid #94795c;
	background-color: rgba(148, 120, 91, 0.08);
}

.main-content-sec .detail-value,
.main-content-sec .change-detail-value {
	margin-top: 16px;
}

.main-content-sec .detail-value label {
	font-size: 15px;
	font-family: Lato-Regular;
	color: #5a5a5a;
}

.main-content-sec .detail-value p {
	font-size: 17px;
	font-family: Lato-Medium;
	color: #303030;
	margin-bottom: 0;
}

.main-content-sec .detail-value span {
	font-size: 16px;
	font-family: Lato-Regular;
	color: #474747;
	margin-bottom: 0;
}

.main-content-sec .direction-contact-btn {
	font-size: 15px;
	font-family: Lato-Regular;
	color: black;
	border: 2px solid #bcbcbc;
	background: white;
	border-radius: 3px;
	padding: 0.5rem;
	margin-top: 8px;
	margin-right: 10px;
	width: 90%;

	@media screen and (min-width: 1200px) {
		width: 70% !important;
	}
}

.details-main-sec.center-btn {
	text-align: center;
}

.main-content-sec .direction-contact-btn:hover {
	color: white !important;
	border: 2px solid var.$trb-gold;
	background: var.$trb-blue;
	border-radius: 3px;
}

.main-content-sec .change-detail-value label {
	font-size: 15px;
	font-family: Lato-Bold;
	color: #303030;
}

.main-content-sec .change-detail-value p {
	font-size: 15px;
	font-family: Lato-Regular;
	color: #303030;
	margin-bottom: 0;
}

/* Update Meeting Method Modal */

.update-meeting-method-modal .modal-content {
	background-color: #fff !important;
	border-color: transparent;
}

.update-meeting-method-modal .modal-body {
	padding: 0 !important;
}

.update-meeting-method-modal .modal-headerr {
	background-color: #fff;
	color: #333;
	padding: 14px 5px;
	border-radius: 0.3rem 0.3rem 0 0;
	border-bottom: 1px solid #e8eaeb;
}

.update-meeting-method-modal .update-meeting-method-main {
	padding: 7px 5px 22px;
}

.update-meeting-method-modal .update-meeting-txt {
	font-size: 16px;
	font-family: Lato-Regular;
}

.update-meeting-method-modal .update-meeting-txt b {
	font-family: Lato-Bold;
}

.update-meeting-method-modal .update-meeting-sec label {
	font-size: 14px;
	font-family: Lato-Bold;
	margin-bottom: 5px;
}

.update-meeting-method-modal .custom-select {
	color: #212121;
	width: 100%;
	border: 1px solid #949494;
	display: block;
	padding: 0.75rem;
	font-size: 1rem;
	transition: border 0.1s;
	border-radius: 0.125rem;
	font-family: Lato-Regular;
}

.update-meeting-method-modal .custom-select:focus {
	outline: 0;
	box-shadow: 0 0 0 3px rgba(148, 120, 91, 0.8);
	border-color: #2979ff;
}

.update-meeting-method-modal .modal-head-label {
	vertical-align: middle;
	font-size: 18px;
	font-family: Lato-Bold;
}

.update-meeting-method-modal .keep-change-btn-sec {
	border-top: 1px solid #e8eaeb;
	padding: 20px 8px;
}

.update-meeting-method-modal .keep-change-btn-alignment {
	text-align: right;
}

.update-meeting-method-modal .keep-btn {
	color: $primary;
	border: 1px solid $primary;
	font-size: 14px;
	font-family: Lato-Bold;
	padding: 4px 12px;
	background-color: #fff;
	border-radius: 3px;
	margin-right: 10px;
}

.update-meeting-method-modal .change-btn {
	background-color: $primary;
	border: 1px solid $primary;
	color: #fff;
	font-size: 14px;
	font-family: Lato-Bold;
	padding: 4px 12px;
	border-radius: 3px;
}

.update-meeting-method-modal .keep-btn:hover {
	color: #fff;
	border: 1px solid $primary-light;
	background-color: $primary-light;
}

.update-meeting-method-modal .change-btn:hover {
	background-color: $primary-light;
	border: 1px solid $primary-light;
}

/* Update Meeting Method Modal End */

/* Cancel Appointment Modal */
.cancel-appointment-modal .modal-content {
	background-color: #fff !important;
	border-color: transparent;
}

.cancel-appointment-modal .modal-body {
	padding: 0 !important;
}

.cancel-appointment-modal .modal-headerr {
	background-color: #fff;
	color: #333;
	padding: 8px 5px;
	border-radius: 0.3rem 0.3rem 0 0;
	/* border-bottom: 1px solid #e8eaeb; */
}

.cancel-appointment-modal .modal-head-label {
	vertical-align: middle;
	font-size: 18px;
	font-family: Lato-Bold;
}

.cancel-appointment-modal .cancel-appointment-main {
	padding: 12px 5px 22px;
}

.cancel-appointment-modal .cancel-appointment-txt {
	font-size: 16px;
	font-family: Lato-Regular;
	color: #303030;
}

.cancel-appointment-modal .cancel-appointment-txt b {
	font-family: Lato-Bold;
}

.cancel-appointment-modal .align-modal-header {
	align-self: center;
}

.cancel-appointment-modal .align-close-icon {
	text-align: end;
}

.cancel-appointment-modal .close-btn {
	border: none;
	background-color: transparent;
}

.cancel-appointment-modal .close-btn img {
	width: 40px;
}

.cancel-appointment-modal .keep-cancel-btn-sec {
	text-align: end;
	margin-top: 35px;
}

.cancel-appointment-modal .keep-btn {
	color: $primary;
	border: 1px solid $primary;
	font-size: 14px;
	font-family: Lato-Bold;
	padding: 4px 12px;
	background-color: #fff;
	border-radius: 3px;
	margin-right: 10px;
}

.cancel-appointment-modal .cancel-appointment-btn {
	background-color: $primary;
	border: 1px solid $primary;
	color: #fff;
	font-size: 14px;
	font-family: Lato-Bold;
	padding: 4px 12px;
	border-radius: 3px;
}

.cancel-appointment-modal .keep-btn:hover {
	color: #fff;
	border: 1px solid $primary-light;
	background-color: $primary-light;
}

.cancel-appointment-modal .cancel-appointment-btn:hover {
	color: #fff;
	background-color: $primary-light;
	border: 1px solid $primary-light;
}

/* Cancel Appointment Modal End */

// Reschedule Appointment Modal

.reschedule-modal .modal-content {
	background-color: #fff !important;
	border-color: transparent;
}

.reschedule-modal .modal-body {
	padding: 0 !important;
}

.reschedule-modal .modal-headerr {
	background-color: #fff;
	color: #333;
	padding: 14px 5px;
	border-radius: 0.3rem 0.3rem 0 0;
	border-bottom: 1px solid #e8eaeb;
}

.reschedule-modal .reschedule-modal-main {
	padding-block: 16px;
}

.reschedule-modal .reschedule-txt {
	font-size: 16px;
	font-family: Lato-Regular;
	margin-bottom: 19px;
}

.reschedule-modal .reschedule-txt b {
	font-family: Lato-Bold;
}

.reschedule-modal .reschedule-sec label {
	font-size: 14px;
	font-family: Lato-Bold;
	margin-bottom: 5px;
}

.reschedule-modal .modal-head-label {
	vertical-align: middle;
	font-size: 18px;
	font-family: Lato-Bold;
}

.reschedule-modal .keep-change-btn-sec {
	border-top: 1px solid #e8eaeb;
	padding: 20px 8px;
}

.reschedule-modal .keep-change-btn-alignment {
	text-align: right;
}

.reschedule-modal .keep-btn {
	color: $primary;
	border: 1px solid $primary;
	font-size: 14px;
	font-family: Lato-Bold;
	padding: 4px 12px;
	background-color: #fff;
	border-radius: 3px;
	margin-right: 10px;
}

.reschedule-modal .change-btn {
	background-color: $primary;
	border: 1px solid $primary;
	color: #fff;
	font-size: 14px;
	font-family: Lato-Bold;
	padding: 4px 12px;
	border-radius: 3px;
}

.reschedule-modal .keep-btn:hover {
	color: #fff;
	border: 1px solid $primary-light;
	background-color: $primary-light;
}

.reschedule-modal .change-btn:hover {
	background-color: $primary-light;
	border: 1px solid $primary-light;
}

.main-content-sec .welcome-msg-sec {
	text-align: center;
	margin-top: 18px;
}

.main-content-sec .welcome-msg-sec h6 {
	font-size: 19px;
	font-family: Lato-Bold;
	color: #303030;
	margin-bottom: 0;
	text-align: center;
}

.main-content-sec .otp-preference-sec {
	margin-block: 22px;
}

.main-content-sec .otp-preference-sec p {
	font-size: 18px;
	font-family: Lato-Regular;
	color: #5a5a5a;
	margin-bottom: 8px;
	text-align: center;
}

.main-content-sec .confirmation-btn-sec {
	text-align: center;
}

.main-content-sec .white-bg-btn {
	background-color: #fff;
	font-size: 16px;
	font-family: Lato-Medium;
	color: black;
	border: 2px solid #bcbcbc;
	border-radius: 3px;
	padding: 4px 8px;
}

.main-content-sec .white-bg-btn:hover {
	background-color: var.$trb-blue;
	border: 2px solid var.$trb-gold;
	border-radius: 3px;
	color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.main-content-sec .gold-bg-btn {
	background-color: white;
	font-size: 16px;
	font-family: Lato-Medium;
	color: black;
	border: 2px solid #bcbcbc;
	border-radius: 3px;
	padding: 4px 8px;
}

.main-content-sec .gold-bg-btn:hover {
	background-color: var.$trb-blue;
	border: 2px solid var.$trb-gold;
	border-radius: 3px;
	color: #fff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.main-content-sec .otp-submission-sec {
	margin-block: 24px;
	box-shadow: 0px 3px 6px #00000029;
	background-color: #fff;
	border-radius: 4px;
	padding: 12px 18px 16px;
}

.main-content-sec .enter-otp-sec {
	margin-bottom: 16px;
}

.main-content-sec .enter-otp-sec label {
	font-size: 16px;
	font-family: Lato-Regular;
	color: #5a5a5a;
	margin-bottom: 2px;
}

.main-content-sec .custom-input {
	color: #212121;
	width: 100%;
	border: 1px solid #949494;
	display: block;
	padding: 0.3rem;
	font-size: 1rem;
	transition: border 0.1s;
	border-radius: 0.125rem;
	font-family: Lato-Regular;
}

.main-content-sec .custom-input:focus {
	outline: 0;
	box-shadow: none;
	border-color: #5a5a5a;
}

.toast-container {
	position: fixed !important;
	top: 20px !important;
	right: 20px !important;
}

.ngx-spinner-overlay {
	position: fixed !important;
}

.modal-checkbox-container {
	display: flex;
	align-items: flex-start;
}

.modal-checkbox-container .checkbox-text {
	padding-left: 10px;
}

// Reschedule Appointment Modal end

/* End here */
.otp-text-input input {
	text-align: center !important;
	font-size: 16px;
	font-weight: bold;
	// letter-spacing: 13px;
}

/*Dx Map Tooltip cross icon START */
app-meeting-location {
	.gm-ui-hover-effect {
		display: none !important;
	}

	.gm-style-iw.gm-style-iw-c {
		// padding-inline-end: 10px !important;
		// padding-bottom: 10px !important;
		// padding-top: 10px !important;
		// padding-inline: 10px !important;
		// padding: 10px !important;

		pointer-events: none;
		top: 32px !important;
		background-color: transparent !important;
		color: white !important;
		font-weight: bold !important;
		width: auto !important;
		height: auto !important;
		padding: 0px !important;
	}

	.gm-style-iw-chr {
		display: none !important;
	}

	.gm-style-iw-tc {
		display: none !important;
	}

	.gm-style-iw-d {
		overflow: auto !important;
	}
}

/*Dx Map Tooltip cross icon END */

// For language selection header in schedular
.language-tabs-sec {
	a {
		&:hover {
			color: $primary-light;
		}
	}
}

.tabs-sec {
	background-color: #024b8118;
	border-radius: 30px;
	display: flex;
}

.language-tab {
	background-color: transparent;
	color: #024b81;
	font-family: Lato-Regular;
	text-decoration: none;
	padding: 4px 20px;
	border-radius: 30px;
	width: 90px;
}

.language-tab.active {
	background-color: #024b81 !important;
	color: #fff !important;
}

.language-dropdown {
	border: none;
	color: $primary;
	font-family: Lato-Regular;
	background: white;
	border: solid $primary 1px;
}

.language-dropdown:focus-visible {
	outline: none;
}

.drop-tabs-sec {
	display: none;
}

@media screen and (max-width: 767px) {
	.with-dropdown .drop-tabs-sec {
		display: block;
	}

	.with-dropdown .tabs-sec {
		display: none;
	}

	.language-tabs-sec {
		&.manage-appointment {
			top: 84px;
		}

		&.with-dropdown.custom-top-margin {
			top: 45px;
		}
	}

	.main-content-sec .details-main-sec {
		padding: 12px 12px;
	}

	.main-content-sec .meeting-details-section {
		padding: 12px 0px 12px 12px;
	}
}

@media screen and (min-width: 768px) {
	.drop-tabs-sec {
		display: none;
	}

	.schedule-appointment .tabs-sec {
		display: flex;
	}
}

//styles for account opening

.account-opening-application-container {
	margin-top: 5rem;
}

.account-opening-application-content-container {
	height: inherit;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.account-opening-header-image-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.account-opening-header-image {
	height: 70px;
}

.account-opening-tabcontent {
	padding: 28px 24px 10px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: #fff;
	position: relative;
}

.account-opening-content-sec-parent {
	text-align: -webkit-center;
}

.account-opening-content-sec {
	margin-block: 18px;
	box-shadow: 0px 3px 6px #00000029;
	width: 700px;
	text-align: left;
}

.account-opening-tab-section {
	// padding-bottom: 16px;
}

.account-opening-blue-btn {
	color: #ffffff;
	border: none;
	background: #063b6c;
	border-radius: 0.25rem;
	font-family: Lato-Bold;
	word-wrap: break-word;
	font-weight: 500;
	white-space: normal;

	cursor: pointer;
	margin: 0;
	display: block;
	padding: 0.5rem;
	box-sizing: border-box;
	transition: border 0.1s;
	width: 200px;
}

.account-opening-white-btn {
	color: #063b6c;
	background-color: transparent;
	border-radius: 0.25rem;
	font-family: Lato-Bold;
	word-wrap: break-word;
	font-weight: 500;
	white-space: normal;

	display: block;
	padding: 0.5rem;
	box-sizing: border-box;
	transition: border 0.1s;
	width: 200px;
	border: 1px solid #063b6c;
}

// .account-opening-blue-btn:hover {
// 	background: #26547f;
// }

// .account-opening-white-btn:hover {
// 	background: #26547f;
// 	color: #fff;
// }

.account-opening-footer-logo-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
}

.account-opening-single-button-container {
	text-align: -webkit-center;
	padding-block: 12px;
}

.account-opening-welcome-text-section {
	width: fit-content;
	padding: 14px;
	background-color: rgb(255, 255, 255, 0.8);
	border-radius: 14px;
	border-top-left-radius: 0 !important;
	margin-left: 13px;
}

.account-opening-section-ul {
	display: inline-block;
	padding-left: 0;
	margin-bottom: 0;
}

.account-opening-section-ul li {
	padding-block: 20px;
}

.account-opening-welcome-header-text {
	color: #002b5c;
	font-family: Lato-Medium;
	font-size: 40px;
}

.center-content-alignment {
	align-self: center;
	align-content: center;
}

.account-opening-background-image-cover {
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.account-opening-details-footer-buttons-section {
	display: flex;
	justify-content: center;
	gap: 8px;
	margin-top: 10px;
}

.account-opening-details-footer-button {
	padding: 5px !important;
	width: 100px !important;
}

.account-opening-form-header-title {
	font-family: Lato-Bold !important;
	font-size: 25px !important;
	font-weight: 500 !important;
	color: #8b7054;
}

.account-opening-form-header-subtitle {
	font-size: 22px !important;
	font-weight: 500 !important;
	color: #024b81 !important;
	font-family: Lato-Bold !important;
}

@media screen and (max-width: 767px) {
	.account-opening-welcome-wrapper {
		text-align: -webkit-center;
	}

	.account-opening-section-ul {
		display: flex;
		gap: 10px;
	}

	.account-opening-welcome-header-text {
		margin-bottom: 0px;
	}

	.account-opening-background-image-cover {
		padding-right: 0px;
		gap: 30px;
	}

	.center-content-alignment {
		padding-bottom: 16px;
	}
}

@media screen and (max-width: 991px) {
	.account-opening-content-sec {
		width: 100%;
	}
}

@media screen and (max-width: 450px) {
	.account-opening-header-image {
		height: auto !important;
		width: 100%;
	}

	.account-opening-welcome-header-text {
		font-size: 28px;
	}

	.account-opening-section-ul li {
		padding-block: 5px;
	}
}

@media screen and (min-width: 481px) and (max-width: 768px) {
	.account-opening-form-header-subtitle {
		font-size: 20px !important;
	}
}

/* Mobile Device */
@media screen and (min-width: 320px) and (max-width: 480px) {
	.account-opening-form-header-title {
		font-size: 20px !important;
		margin: 0 0 10px !important;
	}

	.account-opening-form-header-subtitle {
		font-size: 18px !important;
		margin: 0 0 10px !important;
	}
}

app-account-opening-address-detail {
	.autocomplete-textbox {
		height: 35px !important;
		border: 1px solid #707070 !important;
	}

	.approve-content-wrapper-checkbox .dx-checkbox-icon {
		margin-bottom: 3px !important;
	}
}

app-account-opening-details {
	.disclosure-content-wrapper-checkbox .dx-checkbox-icon {
		border: 2px solid #0c6bc9;
	}
}

app-account-opening-authorization {
	.dx-checkbox-icon {
		border-radius: 0 !important;
		border: 1px solid #0c6bc9 !important;
		background-color: #fff;
		flex-shrink: 0;
		position: relative;
		background-position: 0 0;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

.account-opening-dashboard-header-image {
	width: 35.62rem;
}

.password-container {
	position: relative;
	display: flex;
	align-items: center;
}

.password-eye-icon-button {
	position: absolute;
	right: 10px;
	top: 10px;
	border: none;
	background: transparent;
	cursor: pointer;
	outline: none;
}

.password-icon-style {
	font-size: 18px;
	color: #002b5c;
	font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
	.account-opening-dashboard-header-image {
		width: 100%;
	}
}

@media screen and (min-width: 577px) and (max-width: 767px) {
	.account-opening-dashboard-header-image {
		width: 80%;
	}
}

@media (max-width: 576px) {
	.account-opening-dashboard-header-image {
		width: 95%;
	}
}

.account-opening-dashboard-header-image-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 10px;
}

@media (max-width: 767px) {
	.account-opening-dashboard-header-image-container {
		margin-top: 3rem;
	}
}

@media (max-width: 440px) {
	.account-opening-dashboard-header-image-container {
		margin-top: 4rem;
		margin-bottom: 1.5rem;
	}
}

/* Loader css start */
.default-app-loader-container {
	position: relative;
	top: 150px;
}

#wrapper {
	width: 100%;
	max-width: 725px;
	margin: 0px auto;
}

.gears {
	width: 48%;
	display: inline-block;
}

.gears:first-child {
	margin-right: 3%;
}

.gears-container {
	width: 150px;
	height: 150px;
	font-size: 24px;
	padding: 9%;
	position: relative;
	margin: 0px auto;
}

.gear-rotate {
	width: 32px;
	height: 32px;
	top: 50%;
	left: 50%;
	margin-top: -16px;
	margin-left: -16px;
	background: #1c4595;
	position: absolute;
	border-radius: 16px;
	-webkit-animation: 1s gear-rotate linear infinite;
	-moz-animation: 1s gear-rotate linear infinite;
	animation: 1s gear-rotate linear infinite;
}

.gear-rotate::before {
	width: 44.8px;
	height: 44.8px;
	background:
		-webkit-linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		-webkit-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-webkit-linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	background:
		-moz-linear-gradient(0deg, transparent 39%, #1c4595 39%, #47ec19 61%, transparent 61%),
		-moz-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-moz-linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	background:
		-o-linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		-o-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-o-linear-gradient(120deg, transparent 42%, #47ec19 42%, #1c4595 58%, transparent 58%);
	background:
		-ms-linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		-ms-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-ms-linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	background: linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	position: absolute;
	content: "";
	top: -6.4px;
	left: -6.4px;
	border-radius: 22.4px;
}

.gear-rotate::after {
	width: 16px;
	height: 16px;
	background: #fff;
	position: absolute;
	content: "";
	top: 8px;
	left: 8px;
	border-radius: 8px;
}

.loading-text-sec {
	text-align: center;
}

.loading-text {
	position: absolute;
	top: 70%;
	left: 30%;
	font-size: 14px;
}

@-webkit-keyframes gear-rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes gear-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes gear-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* loader css End */

/* schedular Start */
.center-header-text {
	.content-sec-header {
		justify-content: center !important;
	}
}

/* Schedular End */
